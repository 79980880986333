* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
  background-color: #1e293b;
  color:white;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
}

.gallery {
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

#header {
  margin-bottom: 20px;
  padding: 20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.postcard-display {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Consistent spacing between postcards */
  justify-content: flex-start; /* Make sure postcards are evenly spaced */
}

.postcard {
  height: 250px; /* Fixed height for uniformity */
  display: flex;
  align-items: flex-end; /* Align images to the bottom */
  justify-content: center;
  box-sizing: border-box;
}

.postcard img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure the image fits within the dimensions without cropping */
}


/* Gradient Border Button */
.gradient-border-button {
  position: relative;
  padding: 1.2rem 2.4rem;
  margin: 1.2rem;
  font-size: 1.1rem;
  color: white;
  background: #1e293b;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.gradient-border-button::before {
  content: '';
  position: absolute;
  inset: -3px;
  background: linear-gradient(45deg, #60a5fa, #a78bfa, #60a5fa);
  border-radius: 10px;
  z-index: -1;
  transition: opacity 0.3s ease;
  animation: borderRotate 4s linear infinite;
}

@keyframes borderRotate {
  0% { filter: hue-rotate(0deg); }
  100% { filter: hue-rotate(360deg); }
}

.gradient-border-button:hover,.gradient-border-focussed {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(96, 165, 250, 0.4);
}